<template>
  <v-container>
    <h1>Year Reviews</h1>
    <h2>2020 - The first of many reviews</h2>
    <h3>What went well this year?</h3>
    <h4>I started a new job</h4>
    <p>
      This was a big deal this year, whilst Cloudreach had been a great
      opportunity for me the company changed and my role changed substantially
      and it no longer matched what I enjoyed nor what I needed to progress.
      With Modulr I've found a fast moving company that genuinely wants to
      change things and try out new ideas. My role has changed substantially
      since I joined and I expect it to change even more as time goes on. I've
      also found a great boss which has been crucial throughout the year in
      making it what it has been.
    </p>
    <p>
      I have made substantial changes to how we work in the last year, of the
      day of writing we broke the release train! The first time a Modulr
      microservice can be independently deployed from all the rest. The next
      project is to move the frontend off servers and onto a CDN which again I
      will be heavily involved in.
    </p>
    <h4>I went for a lead position</h4>
    <p>
      Before this year started I would not have done this. I may have gone for a
      senior position but not a lead. Over the year my confidence grew and I
      became more sure of myself. I gave it my best shot and I am pleased with
      the outcome, I got some really good feedback from the CTO Ritesh who was
      really positive and said he'd be keen for me to implement many of the
      things I planned. I didn't get the position but I was up against stiff
      competition from experienced people. I consider this to be a great
      success.
    </p>

    <h4>I started running</h4>
    <p>
      I am not unique in picking up running during the year of Covid. I have
      stuck with it though and from starting Couch to 5K in the Spring I managed
      to do two half marathons in the summer. I continue to run pretty
      frequently and I'm really proud that I can now genuinely call myself a
      runner.
    </p>
    <h4>I started properly saving</h4>
    <p>
      This is another one that is not unique in Covid times. 2020 gave me the
      time to breath and actually start spending time thinking about my
      finances. Since my properly broke year in Cambridge when I was on my
      Industrial placement I have been using Money Dashboard to track where my
      money is going and try and plan where my money will go. I have become
      disollusioned with this approach and I've started using a tool called
      youneedabudget or <a href="https://youneedabudget.com">ynab</a> that I
      highly recommend. I now proactively plan where my money is going and have
      shifted the management of my money from Monzo pots to ynab. Whilst
      undeniably the lack of things to spend money on this year have helped I
      would say the main reason that saving has gone so well has been my more
      proactive approach and how easy ynab makes it to be so.
    </p>
    <h4>I started dating again</h4>
    <p>
      I've been on an unintentional hiatus from dating for about 2 years. When I
      first moved to Edinburgh I decided to focus on work and make sure that I
      actually managed to keep my job. That was the right thing to do for a
      while but then this period seemed to stretch and I half heartedly went on
      tinder and swiped a bit and then went back to living my life.
    </p>
    <p>
      This year I decided to get my arse in gear and do something about it
      because if I carried on like I was then I was going to be alone for a long
      time. Whilst this year was hard it would have been so much harder alone,
      my first relationship of the year fell apart during the first lockdown.
      Hardly a surprise really. The second relationship of the year started in
      the midst of that brief period of summer relaxation and has been going
      brilliantly and I'm so happy that I finally got my act together and more
      importantly that I found someone who I enjoy spending time with so much.
    </p>
    <h4>I bagged my first Munro (with some help)</h4>
    <p>
      My birthday is on the 20th of August and it so happened that the
      restrictions were looser at that time. A friend of mine called Gareth
      offered to drive us to a Munro. This was my first Munro and my first time
      actually spending one on one time with Gareth. The walk was brilliant, we
      swam in the Loch afterwards and on the way back a tyre burst which
      massively frustrated Gareth but replacing it added to the manliness of the
      day.
    </p>
    <p>
      Then we drove back and we went for a meal at an amazing Indian with a
      small group of friends, the most that could be had at that time. This was
      an amazingly happy day for me and the best way I have spent my Birthday in
      a fair few years and was a genuine highlight of the year.
    </p>
    <p>
      Hiking is something I wanted to do on first moving to Scotland but I never
      really did anything about it. This has rekindled my desire to get out
      there in 2021.
    </p>
    <h3>What didn't go well this year?</h3>
    <h4>Burnout is real</h4>
    <p>
      In the last few months of last year I didn't take any holiday, I had 8
      days booked for Christmas and ten I was looking to carry over. I was
      saving those ten days so that I could book an intensive driving course for
      the next year. Within the last few months of the year I was burning out
      quite badly. My days were getting less and less productive and I was
      working longer and longer hours to make up for it. I felt like I was
      hobbling along to wait it out till Christmas.
    </p>
    <h4>I am not a sailor</h4>
    <p>
      Last Christmas I wanted to take up sailing again, I first started sailing
      with the scouts as a teenager and really enjoyed it but I never really
      progressed beyond the basic certificates. Now that I actually live on the
      coast I thought it a brilliant opportunity to take up the hobby once more.
      The first courses of the year were to be in April and by that point a lot
      of things were already off the table due to the restrictions.
    </p>
    <p>
      Whilst I am not foolish enough to blame myself for a global pandemic
      getting in the way of sailing lessons I didn't try once restrictions eased
      in the summer to continue either.
    </p>
    <h4>I am not a driver</h4>
    <p>
      At the end of 2019 I was some way into learning to drive. I did not even
      attempt to continue when my new job started, I rationalised this as the
      need to focus on my job and not take time out from work. This reasoning
      was spurious, I could have easily asked to leave early on some days or
      work from home on some afternoons. As the year went on I wasn't
      comfortable having lessons given the ongoing pandemic.
    </p>
    <h4>Maintaining friendships continues to be a weak point</h4>
    <p>
      Last year was by it's very nature one where messaging was the prevalent
      way of communicating. This is something I am terrible at doing
      consistently. It has meant that few friendships have strengthened this
      year, most have deteriorated and some have stagnated.
    </p>
    <h4>My Personal Technical Development Slowed</h4>
    <p>
      When I first started out I was reading every AWS email and constantly
      striving to learn more about what could be done and I was working on
      personal projects more. This year I barely programmed outside of work, I
      did not keep up with AWS emails as regularly as I did and I did not attend
      any meetup events. This will have to change in the new years as it is
      paramount that my learning does not slow.
    </p>
    <h3>What did I learn?</h3>
    <h4>Focussing on one thing isn't working</h4>
    <p>
      By one thing I don't mean focussing on a single task. I mean focussing on
      a singe life objective, recently all too much around work. This has not
      been an intentional plan but looking back it has been a pattern of
      behaviour.
    </p>
    <h4>Act sooner if my mental health is deteriorating</h4>
    <p>
      Burning out last year was preventable and I should have done something
      about it rather than cling on for the last few months of the year. I need
      to have a way of assessing if it's been a long day or if there is a
      negative trend and do something if it is the latter.
    </p>
    <h4>Hold my self to account for my personal development</h4>
    <p>
      This is insanely important, a short lived CTO at Modulr did say one thing
      that resonated fairly strongly with me. He said that the best thing he
      ever did was to take responsibility for his own development. I think I've
      been waiting too long for a meaningful direction for improvement from
      every job I've been in, I've never recieved a plan or structure that was
      particularly meaningful. So I think I need to make this my problem rather
      than somebody else's.
    </p>
  </v-container>
</template>

<script>
module.exports = {
  data: function() {
    return {
      greeting: "Hello"
    };
  }
};
</script>

<style scoped>
body {
  background-color: white;
}
@import "./assets/markdown.css";
</style>
